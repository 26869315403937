import { GeoJsonLayer } from '@deck.gl/layers'
import DeckMap from './DeckMap'
// import mapsApiClient from './mapsApiClient'
import { Matrix4, Vector3 } from '@math.gl/core'
import { MVTLayer } from '@deck.gl/geo-layers'
import * as turf from '@turf/turf'
import { ASSETS, CLUSTER_ASSETS, EXCLUDED_ASSETS } from './constant'
import { FlyToInterpolator } from '@deck.gl/core'
import { waitMS } from '@/api/utils'

export const tooltipStyle = {
  background: 'var(--colorWhite)',
  color: 'var(--colorHigh)',
  margin: '8px',
  'border-radius': '4px',
  'box-shadow': '0 0 4px 1px rgba(0,0,0, 0.2)',
}

const WORLD_SIZE = 512

const DEFAULT_MVT_PROPS = {
  pointRadiusMinPixels: 2,
  lineWidthMinPixels: 1,
  getFillColor: [130, 109, 186, 255],
  getLineColor: [44, 44, 44],
}

export default {
  layers: {},
  deckInstance: null,
  zoom: 0,

  setZoom(zoom) {
    this.zoom = zoom
  },
  getZoom() {
    return this.zoom
  },
  init(deckProps) {
    this.deckInstance = new DeckMap(deckProps)
  },
  destroy() {
    this.deckInstance.finalize()
    delete this.deckInstance
    this.layers = {}
  },
  hasLayer(id) {
    return !!this.layers[id]
  },
  isVisible(id) {
    // visible = undefined is treated by deckgl as visible = true
    return this.layers[id] && this.layers[id].visible !== false
  },
  getLayerProp(id, prop) {
    return this.layers[id] && this.layers[id][prop]
  },
  isAlreadyInitialized() {
    return !!this.deckInstance
  },
  /*
  async fetchMVTTemplate (sql, options) {
    const data = await mapsApiClient.createMap(sql, options)
    const stats = data.metadata.layers[0].meta.stats
    const urlData = data.metadata.url.vector
    let urls = [urlData.urlTemplate]
    if (urlData.subdomains.length > 0) {
      urls = urlData.subdomains.map(s => urlData.urlTemplate.replace('{s}', s))
    }
    return { urls, stats }
  },
  */
  async addSQLLayer({
    id,
    sql,
    sublayer: SubLayer = GeoJsonLayer,
    requestOptions = {},
    ...props
  }) {
    if (!sql) {
      throw new Error(
        `[layerService.addSQLLayer] sql must be defined. Received ${sql} instead`,
      )
    }
    const { urls, stats } = await this.fetchMVTTemplate(sql, requestOptions)
    // Hack to prevent custom MVT cache
    // TODO; Remove: find a better way to do this, the backend must disable the mvt cache if is possible
    const timestamp = new Date().getTime()
    const urlsWithTimestamp = urls.map((url) => `${url}&timestamp=${timestamp}`)
    this.addLayer({
      id,
      layerType: MVTLayer,
      data: urlsWithTimestamp,
      stats,
      requestOptions,
      ...DEFAULT_MVT_PROPS,
      ...props,
      refinementStrategy: 'no-overlap',
      renderSubLayers:
        props.renderSubLayers ||
        ((innerprops) =>
          new SubLayer({
            ...innerprops,
            extensions: props.disableClipping ? [] : innerprops.extensions,
          })),
    })
  },
  async updateSQLLayer({ id, sql }) {
    if (!this.layers[id]) {
      return
    }
    const requestOptions = this.layers[id].requestOptions
    const { urls, stats } = await this.fetchMVTTemplate(sql, requestOptions)
    this.updateLayer(id, {
      data: urls,
      stats,
    })
  },
  updateDeckInstance(newProps = {}) {
    if (!this.deckInstance) {
      return
    }
    const layers = Object.values(this.layers)
      .sort((l1, l2) => (l1.zIndex || 0) - (l2.zIndex || 0))
      // NOTE: uncomment next line if you are rendering many (>= 5) MVTLayers at the same time
      // .filter(l => this.isVisible(l.id))
      .map(({ layerType: LayerClass, ...props }) => new LayerClass(props))
    if (this.deckInstance) {
      this.deckInstance.setProps({ layers, ...newProps })
    }
  },
  addLayer(layer, updateDeckInstance = true) {
    if (!layer.id) {
      throw new Error(
        `[layerService.addLayer] layer id must defined. Received "${layer.id}" instead`,
      )
    }
    if (typeof layer.layerType !== 'function') {
      throw new Error(
        `[layerService.addLayer] layerType must be a function. Received "${layer.layerType}" instead`,
      )
    }
    this.layers[layer.id] = layer
    if (updateDeckInstance) this.updateDeckInstance()
  },
  updateLayer(id, data, updateDeckInstance = true) {
    if (!this.layers[id]) {
      console.warn(
        'WARNING: Trying to update layer that it does not exist: ',
        id,
      )
      return
    }
    this.layers[id] = {
      ...this.layers[id],
      ...data,
    }
    if (updateDeckInstance) {
      this.updateDeckInstance()
    }
  },
  removeLayer(id) {
    if (this.hasLayer(id)) {
      delete this.layers[id]
      this.updateDeckInstance()
    }
  },
  hideLayer(id, opts = {}, updateDeckInstance = true) {
    if (this.hasLayer(id)) {
      this.layers[id].visible = false
      this.updateLayer(id, { ...opts, visible: false }, updateDeckInstance)
    }
  },
  showLayer(id, opts = {}, updateDeckInstance = true) {
    if (this.hasLayer(id)) {
      this.layers[id].visible = true
      this.updateLayer(id, { visible: true, ...opts }, updateDeckInstance)
    }
  },
  getZoomFactor() {
    const viewport = this.deckInstance.getViewports()[0]
    return viewport.metersPerPixel
  },
  getViewport() {
    return this.deckInstance.getViewports()[0]
  },
  getFrustumPlanes() {
    const viewports = this.deckInstance.getViewports(undefined)
    return viewports[0].getFrustumPlanes()
  },
  getBounds() {
    const viewport = this.deckInstance.getViewports()[0]
    return viewport.getBounds()
  },
  getTransformationMatrixFromTile({ x, y, z }) {
    const worldScale = 2 ** z

    const xScale = WORLD_SIZE / worldScale
    const yScale = -xScale

    const xOffset = (WORLD_SIZE * x) / worldScale
    const yOffset = WORLD_SIZE * (1 - y / worldScale)

    return new Matrix4()
      .translate([xOffset, yOffset, 0])
      .scale([xScale, yScale, 1])
  },
  transformPoint(point, matrix) {
    return matrix.transform(point)
  },
  isPointInViewport(point, planes) {
    const position = new Vector3(point[0], point[1], 0)
    let outDir = null
    for (const direction in planes) {
      const plane = planes[direction]
      if (position.dot(plane.normal) > plane.distance) {
        outDir = direction
        break
      }
    }

    return outDir === null
  },
  isFeatureInViewport(feature, cache, matrix, planes) {
    if (!feature.properties) {
      return false
    }
    // TODO: make this configurable
    const id = feature.properties.cartodb_id || feature.id
    if (cache.has(id)) {
      return false // avoid duplicate features across tiles
    }

    let isInside = false
    const type = feature.geometry.type
    if (type === 'Point') {
      const point = this.transformPoint(feature.geometry.coordinates, matrix)
      isInside = this.isPointInViewport(point, planes)
    } else if (type === 'Polygon') {
      const rings = feature.geometry.coordinates
      isInside = rings.some((polygon) =>
        polygon.some((point) =>
          this.isPointInViewport(this.transformPoint(point, matrix), planes),
        ),
      )
    } else if (type === 'MultiPolygon') {
      const polygons = feature.geometry.coordinates
      isInside = polygons.some((ring) =>
        ring.some((polygon) =>
          polygon.some((point) =>
            this.isPointInViewport(this.transformPoint(point, matrix), planes),
          ),
        ),
      )
    } else {
      // eslint-disable-next-line
      console.error(
        '[layerService.js] Unsupported geometry type for viewportFeatues',
        feature.geometry.type,
      )
    }

    if (isInside) {
      cache.add(id)
    }

    return isInside
  },
  getLayer(layerId) {
    if (!this.deckInstance) {
      return []
    }

    const layer = this.deckInstance.layerManager.layers.find(
      (l) => l.id === layerId,
    )

    return layer
  },
  // code adapted from here: https://github.com/CartoDB/toolkit/blob/jb/viewport-features-generator/packages/viz/src/lib/interactivity/viewport-features/ViewportFeaturesGenerator.ts
  getViewportFeatures(layerId) {
    const layer = this.getLayer(layerId)
    if (!layer) {
      return []
    }

    const cache = new Set()
    const planes = this.getFrustumPlanes()
    const tiles = layer.state.tileset.selectedTiles

    return tiles
      .map((t) => {
        const matrix = this.getTransformationMatrixFromTile(t)
        const features = t.content || []
        return features.filter((f) =>
          this.isFeatureInViewport(f, cache, matrix, planes),
        )
      })
      .flat()
  },
  getViewportFeaturesGeoJSON(layerId) {
    const layer = this.getLayer(layerId)
    if (!layer) {
      return []
    }

    const bbox = turf.bboxPolygon(this.getBounds())
    return layer.props.data.filter((f) =>
      turf.booleanPointInPolygon(turf.point([f.lng, f.lat]), bbox),
    )
  },
  getFilteredDataFromAssetLayer() {
    const clusterLayer = this.getLayer(CLUSTER_ASSETS)
    const assetLayer = this.getLayer(ASSETS)
    if (!clusterLayer && !assetLayer) {
      console.error('No assset layer available, neither cluster nor asset')
      return []
    }

    if (this.isVisible(CLUSTER_ASSETS)) {
      return clusterLayer.props.assetsToShow || clusterLayer.props.data
    }

    if (this.isVisible(ASSETS)) {
      return assetLayer.props.assetsToShow || clusterLayer.props.data
    }
    console.error('No layer visible for assets, neither cluster nor asset')
    return []
  },
  getDataFromAssetLayer() {
    let layer = this.getLayer(ASSETS)
    let layerName = ASSETS
    if (this.isVisible(CLUSTER_ASSETS)) {
      layer = this.getLayer(CLUSTER_ASSETS)
      layerName = CLUSTER_ASSETS
    }
    if (!layer) {
      // If deck does not has the layer prepared, we got directly for the data
      // eslint-disable-next-line no-console
      console.debug(
        `🟡 deck.gl does not have the layer ${layerName} prepared, getting data from backup layers`,
      )
      const layer = this.layers[layerName]
      if (!layer) {
        return []
      } else {
        return layer.data
      }
    }
    return layer.props.data
  },
  getDataFromExcludedAssetLayer() {
    const layer = this.getLayer(EXCLUDED_ASSETS)
    if (layer && this.isVisible(EXCLUDED_ASSETS)) {
      return layer.props.assetsExcluded
    }
    return []
  },
  async waitForLayerManagerToBeReady() {
    const maxRetries = 10
    const msToWaitBetweenRetries = 1000
    let retries = 0
    let layerManager = null
    while (!layerManager && retries < maxRetries) {
      try {
        layerManager = this.deckInstance.layerManager
      } catch (e) {
        console.warn(
          `Deck.gl Layer manager not ready when trying to access. Retrying in ${msToWaitBetweenRetries}ms`,
        )
        await waitMS(msToWaitBetweenRetries)
        retries++
      }
    }
    if (retries === maxRetries) {
      throw new Error('Deck.gl Layer manager not ready when trying to access')
    }
  },
  flyToBbox(boundingBox) {
    console.debug('🛩️  LayerService -> flyTo')
    const TRANSITION_DURATION = 750
    const viewport = this.deckInstance.layerManager.context.viewport
    const { latitude, longitude, zoom } = viewport.fitBounds(boundingBox)
    const viewState = {
      zoom,
      longitude,
      latitude,
      transitionDuration: TRANSITION_DURATION,
      transitionInterpolator: new FlyToInterpolator(),
    }
    this.deckInstance.setProps({ viewState })
  },
}
